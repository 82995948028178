<template>
  <div class="flex flex-row items-center">
    <span
      v-if="falseText"
      class="mr-3 text-gray-900 text-sm font-medium">
      {{ falseText }}
    </span>
    <label
      for="toggle-button"
      class="flex items-center cursor-pointer relative my-auto">
      <input
        id="toggle-button"
        v-model="formValue"
        type="checkbox"
        class="sr-only">
      <div class="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full"></div>
    </label>
    <span
      v-if="trueText"
      class="ml-3 text-gray-900 text-sm font-medium">
      {{ trueText }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, String],
      default: false
    },
    trueText: {
      type: String,
      default: ''
    },
    falseText: {
      type: String,
      default: ''
    },
    trueValue: {
      type: String,
      default: ''
    },
    falseValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    formValue: {
      get () {
        if (this.trueValue && this.value === this.trueValue) {
          return true
        }

        if (this.falseValue && this.value === this.falseValue) {
          return false
        }

        return this.value
      },
      set (val) {
        if (val) {
          this.$emit('input', this.trueValue || val)
        } else {
          this.$emit('input', this.falseValue || val)
        }
      }
    }
  }
}
</script>

<style scoped>
input:checked+.toggle-bg {
    background: #1c64f2;
    border-color: #1c64f2;
}
input:checked+.toggle-bg:after {
    border-color: #fff;
    transform: translateX(100%);
}
.toggle-bg:after {
    background: #fff;
    border-color: #d1d5db;
    border-radius: 9999px;
    border-width: 1px;
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    content: "";
    height: 1.25rem;
    left: .125rem;
    position: absolute;
    top: .125rem;
    transition-duration: .15s;
    transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
    width: 1.25rem;
}
</style>
