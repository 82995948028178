import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class PromotionCodeProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_CHAT_API)
  }

  getMemberPromotionCode (id, query) {
    this.setHeader(getAuthToken())
    return this.get(`/promotion-code/${id}/member`, query)
  }
}

export default PromotionCodeProvider
