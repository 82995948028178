<template>
  <div>
    <form
      class="flex flex-col"
      @submit.prevent="onSearch()">
      <div
        class="grid grid-cols-10 w-full px-3 py-3 rounded-lg"
        style="background-color: #f6f6f6">
        <input
          v-model="searchText"
          type="text"
          placeholder="Search"
          :disabled="disabled"
          class="col-span-9 border-none outline-none rounded text-sm w-full px-1"
          style="background-color: #f6f6f6">
        <div
          class="
            duration-300
            flex
            justify-center
            items-center
            cursor-pointer
            rounded
            text-lg
            text-gray-400
            hover:text-black
          "
          @click="onSearch()">
          <icon icon="solar:magnifer-linear" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    searchText: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onSearch () {
      this.$emit('on-search')
    }
  }
}
</script>
