<template>
  <div class="relative w-full h-full flex flex-row">
    <div class="w-full border-gray-400">
      <chat-area
        v-if="selectedGuest"
        v-bind="selectedGuest"
        @toggle-panel="toggleRightPanel()" />
    </div>
    <div
      v-if="selectedGuest"
      class="fixed z-20 md:relative h-full bg-white duration-500 right-0 border-l border-gray-200"
      :class="(showRightPanel) ? 'right-panel' : 'close-panel'">
      <member-detail
        v-if="showRightPanel || loadingRightPanel"
        :uid="selectedGuest.uid"
        class="duration-500"
        :class="(showRightPanel) ? 'opacity-100' : 'opacity-0'"
        @toggle-panel="toggleRightPanel()" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SocialGuestProvider from '../../../resources/social-guest.provider'
import ChatArea from '../components/ChatArea.vue'
import MemberDetail from '../components/MemberDetail.vue'

const SocialGuestService = new SocialGuestProvider()

export default {
  components: { ChatArea, MemberDetail },
  data: () => ({
    loading: false,
    showRightPanel: false,
    loadingRightPanel: false
  }),
  computed: {
    ...mapGetters({
      selectedGuest: 'Guest/selectedGuest'
    }),
    userId () {
      return this.$route.params?.uid || null
    }
  },
  mounted () {
    if (this.userId && this.selectedGuest?.uid !== this.userId) {
      this.getUserInfo()
    }
  },
  methods: {
    ...mapActions({
      setSelectedGuest: 'Guest/setSelectedGuest'
    }),
    toggleRightPanel () {
      if (!this.loadingRightPanel) {
        this.loadingRightPanel = true
        this.showRightPanel = !this.showRightPanel
        setTimeout(() => {
          this.loadingRightPanel = false
        }, 500)
      }
    },
    async getUserInfo () {
      try {
        this.loading = true

        const { data } = await SocialGuestService.getGuestByUid(this.userId)
        this.setSelectedGuest(data)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.turn-left {
  transform: rotateZ(180deg);
}
.turn-right {
  transform: rotateZ(0deg);
}
.right-panel {
  width: 600px;
}
.close-panel {
  width: 0;
}

@media (max-width: 767px) {
  .right-panel {
    width: 100vw;
  }
}
</style>
