<template>
  <div
    class="w-full h-full flex justify-center items-center border-dashed border-2 border-gray-400 rounded cursor-pointer"
    @dragover.prevent
    @drop.prevent="drop($event)"
    @click="chooseFile()">
    <input
      id="fileBrowser"
      type="file"
      multiple
      style="display:none"
      @change="uploadFromInput()" />
    <div class="mx-auto my-auto text-sm text-gray-400">
      Drag and drop or
      <span class="text-gray-700">
        select file
      </span>
      to upload
    </div>
    <!-- <icon
      icon="majesticons:plus"
      color="#000"
      class="text-2xl" /> -->
  </div>
</template>

<script>
import { uploadToBucket } from '../assets/js/AwsS3'

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    items: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    chooseFile () {
      document.getElementById('fileBrowser').click()
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onloadend = (f) => {
          resolve({
            preview: f.target.result,
            mediaUrl: null,
            mediaName: 'Uploading...'
          })
        }

        reader.onerror = () => {
          reject(reader)
        }

        reader.readAsDataURL(file)
      })
    },
    async uploadFromInput () {
      const { files } = document.getElementById('fileBrowser')
      const readFiles = []
      const promises = []
      const len = this.items.length

      for (const file of files) {
        promises.push(uploadToBucket(file, '/_chat-upload'))
        readFiles.push(this.getBase64(file))
      }

      this.items.push(...await Promise.all(readFiles))
      const results = await Promise.all(promises)

      for (const [index, result] of results.entries()) {
        this.items[len + index].mediaName = result.mediaName
        this.items[len + index].mediaUrl = result.mediaUrl
      }
    },
    async drop (e) {
      const { files } = e.dataTransfer

      const readFiles = []
      const promises = []
      const len = this.items.length

      for (const file of files) {
        promises.push(uploadToBucket(file, '/_chat-upload'))
        readFiles.push(this.getBase64(file))
      }

      this.items.push(...await Promise.all(readFiles))
      const results = await Promise.all(promises)

      for (const [index, result] of results.entries()) {
        this.items[len + index].mediaName = result.mediaName
        this.items[len + index].mediaUrl = result.mediaUrl
      }
    },
    removeItem (index) {
      this.items.splice(index, 1)
    }
  }
}
</script>
