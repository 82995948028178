<!-- eslint-disable max-len -->
<template>
  <div
    v-if="!loading"
    class="w-full h-full px-5 flex flex-col items-center overflow-y-auto">
    <div
      v-if="!isMoreOrdersActive && !isMorePromotionActive && memberData"
      class="w-full">
      <div
        class="text-2xl text-black cursor-pointer self-start pt-5 md:hidden"
        @click="togglePanel()">
        <icon icon="majesticons:close" />
      </div>
      <div class="w-full h-2/12 flex flex-col justify-center items-center">
        <span class="pt-10 font-semibold">
          {{ memberData.firstName }} {{ memberData.lastName }}
        </span>
        <span
          class="px-5 mt-1 mb-10 font-semibold rounded"
          :class="checkMemberType()">
          {{ memberData.type.toUpperCase() }}
        </span>
      </div>
      <div class="w-full text-left mb-10">
        <div v-if="memberData.spending">
          ยอดซื้อ : {{ memberData.spending.current | showNumberFormat() }}
        </div>
        <div>
          เบอร์ : {{ memberData.tel }}
        </div>
        <div>
          วันเกิด : {{ memberData.birthDate }}
        </div>
        <div>
          email : {{ memberData.email }}
        </div>
      </div>
      <div class="w-full h-4/12 flex flex-col justify-center items-center">
        <span class="font-semibold self-start mb-5">
          ประวัติการสั่งซื้อ
        </span>
        <div class="overflow-x-auto w-full">
          <table class="w-96 md:w-full text-sm mb-4">
            <thead>
              <tr>
                <th>วันที่</th>
                <th>ออเดอร์</th>
                <th class="text-right">
                  ราคาสุทธิ
                </th>
                <th>โค้ด</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(payload, i) in orders.results"
                :key="i">
                <td class="text-center">
                  {{ getDateTimeFormat(payload.createdAt) }}
                </td>
                <td>
                  <div class="flex flex-row justify-center items-center">
                    <div
                      class="rounded-full w-1 h-1 mr-0.5"
                      :class="isExpired(payload)" />
                    <a
                      :href="getBillUrl(payload)"
                      class="no-underline text-black"
                      target="_blank">
                      {{ payload.order.id }}
                    </a>
                  </div>
                </td>
                <td class="text-right">
                  {{ payload.order.net | showNumberFormat() }}
                </td>
                <td class="text-center">
                  {{ checkPromotionCode(payload.promotionCode) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span
          v-if="orders.total > 5"
          class="self-end mb-5 underline cursor-pointer text-sm"
          @click="showMoreOrders()">
          เพิ่มเติม
        </span>
      </div>
      <div class="w-full h-4/12 flex flex-col justify-center items-center">
        <span class="font-semibold self-start mb-5">
          โค้ดส่วนลดของลูกค้า
        </span>
        <table class="w-full">
          <thead>
            <tr>
              <th class="font-semibold">
                Code
              </th>
              <th class="font-semibold">
                มูลค่า
              </th>
              <th class="font-semibold">
                วันหมดอายุ
              </th>
              <th class="font-semibold">
                การใช้งาน
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(promo, i) in promotionCodes.results"
              :key="i">
              <td class="text-center text-sm">
                {{ promo.code }}
              </td>
              <td class="text-center text-sm">
                {{ checkDiscount(promo.discountNumber, promo.discountType) }}
              </td>
              <td class="text-center text-sm">
                {{ getExpireDate(promo.dateRange) }}
              </td>
              <td class="text-center text-sm">
                {{ checkPromoStatus(promo.status) }}
              </td>
            </tr>
          </tbody>
        </table>
        <span
          v-if="promotionCodes.total > 5"
          class="self-end mt-5 underline cursor-pointer text-sm"
          @click="showMorePromotion()">
          เพิ่มเติม
        </span>
      </div>
      <div class="w-full h-3/12 flex flex-col justify-center items-center mt-5">
        <span class="font-semibold self-start mb-3">
          Tag ของลูกค้า
        </span>
        <dropdown
          v-model="guestData.tags"
          :items="mockupTags"
          label="Tags"
          class="mb-5"
          :disabled="tagLoading"
          @on-select="selectedTag($event)"
          @on-remove="removeTag($event)" />
      </div>
      <div class="w-full h-4/12 flex flex-col justify-center items-center">
        <span class="font-semibold self-start">
          Note
        </span>
        <input
          v-model="todoInput"
          placeholder="Enter note here"
          type="text"
          class="mt-3 mb-5 w-full h-10 outline-none border border-gray-200 hover:border-gray-400 focus:border-gray-400 px-1 duration-300 rounded"
          :disabled="todoLoading"
          @keydown.enter="addTodo()">
        <div class="w-full h-20 overflow-y-auto mb-5">
          <div
            v-for="(todo, t) in guestData.todos"
            :key="t"
            class="w-full flex flex-row justify-between items-center mb-2">
            <span :class="checkTodoStatus(todo.status)">
              {{ todo.text }}
            </span>
            <div
              v-if="todo.status === 'todo'"
              class="text-xl cursor-pointer text-gray-400 hover:text-black duration-300"
              @click="checkedTodo(t)">
              <icon icon="solar:check-square-linear" />
            </div>
            <div
              v-else
              class="text-xl cursor-pointer text-gray-400 hover:text-black duration-300"
              @click="removeTodo(t)">
              <icon icon="solar:trash-bin-trash-linear" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="isMoreOrdersActive"
      class="w-full h-full">
      <more-orders
        :id="memberData.id"
        @on-close="closeMoreOrders()" />
    </div>
    <div
      v-else-if="isMorePromotionActive"
      class="w-full h-full">
      <more-promotion
        :id="memberData.id"
        @on-close="closeMorePromotion()" />
    </div>
    <div
      v-else
      class="w-full h-full flex justify-center items-center">
      <div
        class="text-2xl text-black cursor-pointer absolute top-5 left-5 md:hidden"
        @click="togglePanel()">
        <icon icon="majesticons:close" />
      </div>
      <span>
        No data found.
      </span>
    </div>
  </div>
  <div
    v-else
    class="w-full h-full flex justify-center items-center">
    <loading />
  </div>
</template>

<script>
import SocialGuestProvider from '../../../resources/social-guest.provider'
import MemberProvider from '../../../resources/member.provider'
import MemberOrderHistoriesProvider from '../../../resources/member-order-histories.provider'
import PromotionCodeProvider from '../../../resources/promotion-code.provider'
import Dropdown from '../../../components/Dropdown.vue'
import MoreOrders from './MemberDetail/MoreOrders.vue'
import MorePromotion from './MemberDetail/MorePromotion.vue'

const SocialGuestService = new SocialGuestProvider()
const MemberService = new MemberProvider()
const MemberOrderHistoriesService = new MemberOrderHistoriesProvider()
const PromotionCodeService = new PromotionCodeProvider()

export default {
  components: { Dropdown, MoreOrders, MorePromotion },
  props: {
    uid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      todoLoading: false,
      tagLoading: false,
      loading: false,
      todoInput: '',
      mockupTags: [
        'รอแอดมิน',
        'เปลี่ยนของ',
        'คอมเพลน'
      ],
      guestData: null,
      memberData: null,
      orders: null,
      promotionCodes: null,
      isMoreOrdersActive: false,
      isMorePromotionActive: false
    }
  },
  watch: {
    uid () {
      this.initMemberDetail()
    }
  },
  mounted () {
    this.initMemberDetail()
  },
  methods: {
    getBillUrl (payload) {
      return `${process.env.VUE_APP_LINK_BILL}/${payload.order.url}`
    },
    async initMemberDetail () {
      if (!this.uid) {
        return
      }
      try {
        this.loading = true

        this.guestData = null
        this.memberData = null
        this.orders = null
        this.promotionCodes = null

        const { data: guest } = await SocialGuestService.getGuestByUid(this.uid)

        if (!guest?.member) {
          return
        }

        const { data: member } = await MemberService.getMemberDetail(guest.member.id)
        const { data: orders } = await MemberOrderHistoriesService.getMemberOrderHistories(guest.member.id, {
          page: 1,
          limit: 5
        })
        const { data: promotions } = await PromotionCodeService.getMemberPromotionCode(guest.member.id, {
          page: 1,
          limit: 5
        })

        this.guestData = guest
        this.memberData = member
        this.orders = orders
        this.promotionCodes = promotions
      } catch (error) {
        console.error('initMemberDetail', error)
      } finally {
        this.loading = false
      }
    },
    checkMemberType () {
      let className = 'member'

      if (this.memberData.type === 'vvip') {
        className = 'vvip'
      } else if (this.memberData.type === 'vip') {
        className = 'vip'
      }

      return className
    },
    isExpired (payload) {
      const diff = this.$dayjs().isAfter(this.$dayjs(payload.order.expire))

      if (diff && payload.order.currentState === 'confirmed') {
        return 'expired'
      }

      return payload.order.currentState
    },
    checkPromotionCode (promotionCode) {
      if (!promotionCode || !promotionCode?.code) {
        return '-'
      }

      return promotionCode.code
    },
    checkDiscount (number, type) {
      if (type === 'percentage') {
        return `${number}%`
      }

      return number
    },
    getExpireDate (dateRange) {
      if (dateRange.endDate) {
        return this.$dayjs(dateRange.endDate).format('DD MMM YYYY')
      }

      return '-'
    },
    checkPromoStatus (status) {
      return (status === 'used') ? 'Yes' : 'No'
    },
    getDateTimeFormat (dateTime) {
      return this.$dayjs(dateTime).format('DD/MM/YY HH.mm')
    },
    async selectedTag (tag) {
      const tmpTags = [...this.guestData.tags]

      try {
        this.tagLoading = true

        await SocialGuestService.addTag(this.guestData.id, tag)
      } catch (error) {
        console.error('selectedTag', error)
        this.guestData.tags = tmpTags
      } finally {
        this.tagLoading = false
      }
    },
    async removeTag (tagIndex) {
      const tmpTags = [...this.guestData.tags]

      try {
        this.tagLoading = true
        await SocialGuestService.deleteTag(this.guestData.id, tagIndex)
      } catch (error) {
        console.error('removeTag', error)
        this.guestData.tags = tmpTags
      } finally {
        this.tagLoading = false
      }
    },
    async addTodo () {
      if (this.todoInput) {
        try {
          this.todoLoading = true
          const { data } = await SocialGuestService.addTodo(this.guestData.id, this.todoInput)
          this.guestData.todos = data.todos
          this.todoInput = ''
        } catch (error) {
          console.error('addTodo', error)
        } finally {
          this.todoLoading = false
        }
      }
    },
    checkTodoStatus (status) {
      return (status === 'done') ? 'line-through' : ''
    },
    async checkedTodo (todoIndex) {
      try {
        this.todoLoading = true
        const { data } = await SocialGuestService.updateTodo(this.guestData.id, todoIndex)
        this.guestData.todos[todoIndex] = data.todos[todoIndex]
      } catch (error) {
        console.error('checkedTodo', error)
      } finally {
        this.todoLoading = false
      }
    },
    async removeTodo (todoIndex) {
      try {
        this.todoLoading = true
        await SocialGuestService.deleteTodo(this.guestData.id, todoIndex)
        this.guestData.todos.splice(todoIndex, 1)
      } catch (error) {
        console.error('removeTodo', error)
      } finally {
        this.todoLoading = false
      }
    },
    showMoreOrders () {
      this.isMoreOrdersActive = true
    },
    showMorePromotion () {
      this.isMorePromotionActive = true
    },
    closeMoreOrders () {
      this.isMoreOrdersActive = false
    },
    closeMorePromotion () {
      this.isMorePromotionActive = false
    },
    togglePanel () {
      this.$emit('toggle-panel')
    }
  }
}
</script>

<style scoped>
.vvip {
  background-color: #FF8181;
}
.vip {
  background-color: #FED992;
}
.member {
  background-color: #DBD3D3;
}
.paid {
  background-color: #9A648B;
}
.waiting {
  background-color: #60C3D7;
}
.expired {
  background-color: #CACACA;
}
.pending {
  background-color: #BFCB4D;
}
.reserved, .confirmed {
  background-color: #EDA441;
}
.completed {
  background-color: #2D5D72;
}
</style>
