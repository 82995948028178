<template>
  <div
    class="flex flex-row max-w-max gap-x-2"
    :class="(messageType === 'send') ? 'self-end' : ''">
    <div
      :class="(messageType === 'send') ? 'order-last' : ''"
      style="width: 40px; height: 40px; object-fit: contain;">
      <img
        :src="sendProfile"
        alt="Profile"
        width="100%"
        height="auto"
        class="rounded-full">
    </div>
    <div class="flex flex-col">
      <div
        class="w-full mb-2 text-xs"
        :class="(messageType === 'send') ? 'text-right' : 'text-left'">
        <span class="font-semibold">
          {{ sendBy }}
        </span>
      </div>
      <div
        v-if="message.type === 'text'"
        style="white-space: pre-line"
        class="text-sm p-2 rounded-lg bubble-max-width text-black"
        :class="(messageType === 'send') ? 'sender-bg self-end rounded-tr-none' : 'target-bg rounded-tl-none'">
        {{ message.text }}
      </div>
      <div
        v-else-if="message.type === 'image'"
        class="w-full flex cursor-pointer rounded-lg p-2"
        :class="(messageType === 'send') ? 'justify-end sender-bg' : 'justify-start target-bg'"
        @click="showPreview(message.originalContentUrl, 'Image')">
        <img
          class="w-32 h-32 object-contain"
          :src="message.originalContentUrl"
          alt="Image">
      </div>
      <div
        v-else-if="message.type === 'video'"
        class="w-full flex cursor-pointer rounded-lg"
        :class="(messageType === 'send') ? 'justify-end' : 'justify-start'">
        <video
          width="240"
          controls>
          <source
            :src="message.originalContentUrl"
            type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
      <div
        v-else-if="message.type === 'audio'"
        class="w-full flex cursor-pointer rounded-lg"
        :class="(messageType === 'send') ? 'justify-end' : 'justify-start'">
        <audio controls>
          <source
            :src="message.originalContentUrl"
            type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </div>
      <div
        v-else-if="message.type === 'sticker'"
        class="w-full flex"
        :class="(messageType === 'send') ? 'justify-end' : 'justify-start'">
        <img
          class="rounded w-32 h-32 object-contain"
          :src="`https://stickershop.line-scdn.net/stickershop/v1/sticker/${message.stickerId}/android/sticker.png`"
          alt="Sticker">
      </div>
      <div
        v-else
        class="flex flex-row flex-wrap text-sm p-2 rounded-lg text-black"
        :class="(messageType === 'send') ? 'sender-bg rounded-tr-none' : 'target-bg rounded-tl-none'">
        ไม่สามารถแสดงข้อความนี้บน Browser ได้
      </div>
      <div
        class="w-full mt-2 text-xs"
        :class="(messageType === 'send') ? 'text-right' : 'text-left'">
        <span style="color: #cecece;">
          {{ getTimeFormat(sendTime) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: [Object, String],
      default: () => {}
    },
    messageType: {
      type: String,
      default: ''
    },
    sendBy: {
      type: String,
      default: ''
    },
    sendProfile: {
      type: [String, Object],
      default: ''
    },
    sendTime: {
      type: String,
      default: ''
    }
  },
  methods: {
    getTimeFormat (time) {
      const timeFormat = this.$dayjs(time).format('HH:mm')
      const dateFormat = this.$dayjs(time).format('D MMM YY')
      return `${timeFormat} | ${dateFormat}`
    },
    showPreview (src, alt) {
      this.$emit('on-show-preview', { src, alt })
    }
  }
}
</script>

<style scoped>
.target-bg {
  background-color: #efefef;
}
.sender-bg {
  background-color: #dedede;
}
.bubble-max-width {
  width: fit-content;
  word-wrap: break-word;
  max-inline-size: 50ch;
}

@media screen and (max-width: 768px) {
  .bubble-max-width {
    max-inline-size: 35ch;
  }
}
</style>
