<template>
  <div class="fixed md:relative z-10 bottom-0 left-0 grid grid-cols-10 gap-x-3 w-full reply-height px-10 bg-white">
    <div class="relative mx-auto my-auto">
      <div
        class="text-gray-400 hover:text-black duration-300 text-2xl cursor-pointer"
        @click="toggleOptions()">
        <icon icon="solar:add-square-linear" />
      </div>
      <div
        class="absolute z-30 -top-3 left-3 transform -translate-x-1/2 -translate-y-full rounded-lg elevation-3 flex flex-col bg-white duration-300"
        :class="isShowOptions ? 'visible opacity-100' : 'invisible opacity-0'">
        <div
          class="flex flex-row items-center gap-x-1 p-3 text-base rounded-t-lg hover:bg-gray-200 duration-300 cursor-pointer"
          @click="toggleFileMessage()">
          <icon icon="solar:clipboard-add-linear" />
          <span>
            File
          </span>
        </div>
        <div
          class="flex flex-row items-center gap-x-1 p-3 text-base hover:bg-gray-200 duration-300 cursor-pointer"
          @click="toggleImageMessage()">
          <icon icon="solar:camera-add-linear" />
          <span>
            Image
          </span>
        </div>
        <div
          class="flex flex-row items-center gap-x-1 p-3 text-base rounded-b-lg hover:bg-gray-200 duration-300 cursor-pointer"
          @click="toggleQuickMessage()">
          <icon icon="mingcute:message-3-line" />
          <span>
            Messages
          </span>
        </div>
      </div>
    </div>
    <div class="relative col-span-8 mx-auto my-auto w-full flex flex-row items-center justify-center duration-300">
      <textarea
        v-model="messageInput"
        type="text"
        placeholder="Write a message..."
        class="w-full h-10 outline-none border border-white hover:border-gray-400 focus:border-gray-400 px-2 rounded-lg duration-300"
        autofocus
        :autocomplete="false"
        :readonly="loading"
        @keydown="handleEnterKey" />
      <loading
        v-if="loading"
        size="16px"
        class="absolute right-2.5" />
    </div>
    <div
      class="text-2xl cursor-pointer mx-auto my-auto text-gray-400 hover:text-black duration-300"
      @click="toggleStickerMessage()">
      <icon icon="solar:sticker-smile-square-linear" />
    </div>
    <quick-message
      v-if="isShowQuickMessage"
      @push-message="pushMessage($event)"
      @set-message="setMessage($event)" />
    <image-message
      v-if="isShowImageMessage"
      v-model="uploadImages" />
    <file-message
      v-if="isShowFileMessage"
      v-model="uploadFiles" />
    <sticker-message
      v-if="isShowStickerMessage"
      v-model="selectedSticker"
      @on-select="sendSticker()" />
  </div>
</template>

<script>
import SocialChatProvider from '../../../resources/social-chat.provider'
import { getAuthDecode } from '../../../utils/auth.utils'
import FileMessage from './ReplyMessage/FileMessage.vue'
import ImageMessage from './ReplyMessage/ImageMessage.vue'
import QuickMessage from './ReplyMessage/QuickMessage.vue'
import StickerMessage from './ReplyMessage/StickerMessage.vue'

const SocialChatService = new SocialChatProvider()

export default {
  components: { QuickMessage, ImageMessage, FileMessage, StickerMessage },
  props: {
    guestData: {
      type: Object,
      default: null
    },
    uid: {
      type: String,
      default: ''
    },
    channel: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      messageInput: '',
      user: null,
      uploadImages: [],
      uploadFiles: [],
      selectedSticker: null,
      isShowOptions: false,
      isShowQuickMessage: false,
      isShowImageMessage: false,
      isShowFileMessage: false,
      isShowStickerMessage: false
    }
  },
  mounted () {
    this.user = getAuthDecode()
  },
  methods: {
    handleEnterKey (e) {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        this.pushMessage()
      }
    },
    setMessage (message) {
      this.messageInput = message
      this.toggleQuickMessage()
    },
    async pushMessage (message) {
      if (this.loading) {
        return
      }

      try {
        this.loading = true

        if (this.uid && this.channel === 'LINE') {
          if (this.messageInput || message) {
            this.messageInput = message || this.messageInput

            await SocialChatService.pushLINEMessages({
              to: this.uid,
              messages: [{
                type: 'text',
                text: this.messageInput
              }]
            })

            this.messageInput = ''
          }

          if (this.isShowImageMessage && this.uploadImages) {
            const messages = []

            for (const image of this.uploadImages) {
              messages.push({
                type: 'image',
                originalContentUrl: image.mediaUrl,
                previewImageUrl: image.mediaUrl
              })
            }

            await SocialChatService.pushLINEImages({
              to: this.uid,
              messages
            })

            this.uploadImages = []
          }

          if (this.isShowFileMessage && this.uploadFiles) {
            const messages = []

            for (const file of this.uploadFiles) {
              messages.push({
                type: 'text',
                text: `${file.mediaName}\n${file.mediaUrl}`
              })
            }

            await SocialChatService.pushLINEMessages({
              to: this.uid,
              messages
            })

            this.uploadFiles = []
          }

          this.isShowQuickMessage = false
          this.isShowImageMessage = false
          this.isShowFileMessage = false
        }

        if (this.uid && this.channel === 'facebook') {
          if (this.messageInput || message) {
            this.messageInput = message || this.messageInput

            await SocialChatService.pushFacebookMessage({
              pageId: this.guestData.providerChannel.pageId,
              messagingType: 'RESPONSE',
              recipient: { id: this.uid },
              message: {
                text: this.messageInput
              }
            })

            this.messageInput = ''
          }

          if (this.isShowImageMessage && this.uploadImages) {
            const sendImages = []
            for (const image of this.uploadImages) {
              sendImages.push(SocialChatService.pushFacebookImage({
                pageId: this.guestData.providerChannel.pageId,
                messagingType: 'RESPONSE',
                recipient: { id: this.uid },
                message: {
                  attachment: {
                    type: 'image',
                    payload: {
                      url: image.mediaUrl,
                      isReusable: true
                    }
                  }
                }
              }))
            }

            await Promise.all(sendImages)

            this.uploadImages = []
          }

          if (this.isShowFileMessage && this.uploadFiles) {
            const messages = []

            for (const file of this.uploadFiles) {
              messages.push(SocialChatService.pushFacebookMessage({
                pageId: this.guestData.providerChannel.pageId,
                messagingType: 'RESPONSE',
                recipient: { id: this.uid },
                message: {
                  text: `${file.mediaName}\n${file.mediaUrl}`
                }
              }))
            }

            await Promise.all(messages)

            this.uploadFiles = []
          }

          this.isShowQuickMessage = false
          this.isShowImageMessage = false
          this.isShowFileMessage = false
        }
      } catch (error) {
        console.error('pushMessage', error)
      } finally {
        this.loading = false
      }
    },
    async sendSticker () {
      this.toggleStickerMessage()

      if (this.loading) {
        return
      }

      try {
        this.loading = true

        if (this.uid && this.channel === 'LINE') {
          await SocialChatService.pushLINEStickers({
            to: this.uid,
            messages: [{
              type: 'sticker',
              packageId: this.selectedSticker.packageId,
              stickerId: this.selectedSticker.stickerId
            }]
          })

          this.selectedSticker = null
        }
      } catch (error) {
        console.error('sendSticker', error)
      } finally {
        this.loading = false
      }
    },
    getFirstLetter (text) {
      const regex = /([A-z])/
      const result = regex.exec(text)

      if (result) {
        return result[0].toUpperCase()
      }

      return 'G'
    },
    isNull (obj, target) {
      return obj && obj[target]
    },
    toggleOptions () {
      this.isShowOptions = !this.isShowOptions
      this.isShowStickerMessage = false
    },
    toggleQuickMessage () {
      this.isShowOptions = false
      this.isShowImageMessage = false
      this.isShowFileMessage = false
      this.isShowStickerMessage = false
      this.isShowQuickMessage = !this.isShowQuickMessage
    },
    toggleImageMessage () {
      this.isShowOptions = false
      this.isShowQuickMessage = false
      this.isShowFileMessage = false
      this.isShowStickerMessage = false
      this.isShowImageMessage = !this.isShowImageMessage
    },
    toggleFileMessage () {
      this.isShowOptions = false
      this.isShowQuickMessage = false
      this.isShowImageMessage = false
      this.isShowStickerMessage = false
      this.isShowFileMessage = !this.isShowFileMessage
    },
    toggleStickerMessage () {
      this.isShowOptions = false
      this.isShowQuickMessage = false
      this.isShowImageMessage = false
      this.isShowFileMessage = false
      this.isShowStickerMessage = !this.isShowStickerMessage
    }
  }
}
</script>

<style scoped>
.reply-height {
  height: 79px;
}
</style>
