<template>
  <div class="w-full overflow-y-auto overflow-x-hidden chat-area">
    <div
      v-if="getting"
      class="w-full flex justify-center items-center"
      style="background-color: #F9F9F9;">
      <loading />
    </div>
    <div
      id="chat-area-facebook"
      class="w-full h-full overflow-y-auto overflow-x-hidden"
      style="background-color: #F9F9F9;">
      <div
        v-for="(msg, i) in messages"
        :key="i"
        class="w-full flex flex-col p-2">
        <facebook-chat-bubble
          :message="msg"
          @on-show-preview="showPreview($event)" />
      </div>
    </div>
    <image-preview
      v-if="isShowPreview"
      :src="showPreviewSource.src"
      :alt="showPreviewSource.alt"
      @on-close="closePreview()" />
  </div>
</template>

<script>
import axios from 'axios'
import SocialChatProvider from '../../../resources/social-chat.provider'
import FacebookChatBubble from './FacebookChatBubble.vue'
import ImagePreview from './ImagePreview.vue'

const SocialChatService = new SocialChatProvider()
export default {
  components: { FacebookChatBubble, ImagePreview },
  props: {
    guestData: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      getting: false,
      messages: [],
      showPreviewSource: {
        src: '',
        alt: ''
      },
      nextPageUrl: null,
      isShowPreview: false
    }
  },
  computed: {
    uid () {
      return this.$route.params.uid ? this.$route.params.uid : ''
    }
  },
  watch: {
    guestData: {
      deep: true,
      handler () {
        this.initMessage(true)
      }
    }
  },
  async mounted () {
    this.sockets.subscribe('NEW_MESSAGE', (data) => {
      if (data.guest.uid === this.uid) {
        this.messages.push(data)
        setTimeout(() => {
          this.initScrollY()
        }, 50)
      }
    })
    document.getElementById('chat-area-facebook').addEventListener('scroll', this.handleScroll)
    await this.initMessage(true)
  },
  beforeDestroy () {
    document.getElementById('chat-area-facebook').removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    initScrollY () {
      document.getElementById('chat-area-facebook').scrollTo(0, document.getElementById('chat-area-facebook').scrollHeight)
    },
    handleScroll (event) {
      const e = event.target

      if (e.scrollTop <= 0 && !this.getting) {
        this.initMessage(false, false)
      }
    },
    async initMessage (isClear = false, isScroll = true) {
      try {
        this.getting = true

        if (isClear) {
          this.messages = []
          this.page = 0
          this.totalPage = 1
        }

        if (this.page === 0) {
          this.page++

          const { data } = await SocialChatService.getMessageFacebook({
            uid: this.uid,
            pageId: this.guestData.providerChannel.pageId
          })

          this.nextPageUrl = data?.messages?.paging?.next || null

          const tmpResults = data?.messages?.data || []
          this.messages.unshift(...tmpResults.reverse())
        } else if (this.nextPageUrl !== null) {
          const { data } = await axios.get(this.nextPageUrl)

          const tmpResults = data.data
          this.nextPageUrl = data.paging?.next || null
          this.messages.unshift(...tmpResults.reverse())
        }
        if (isScroll) {
            setTimeout(() => {
              this.initScrollY()
            }, 50)
          }
      } catch (error) {
        console.error('initMessage', error)
      } finally {
        this.getting = false
      }
    },
    closePreview () {
      this.isShowPreview = false
      this.showPreviewSource = {
        src: '',
        alt: ''
      }
    },
    showPreview ({ src, alt }) {
      this.showPreviewSource = {
        src,
        alt
      }
      this.isShowPreview = true
    }
  }
}
</script>

<style scoped>
.chat-area {
  height: calc(100vh - 136px);
}

@media (max-width: 767px) {
  .chat-area {
    height: calc(100vh - 79px);
    padding-top: 57px;
  }
}
</style>
