<template>
  <div class="mt-5 w-full h-full flex flex-col items-center">
    <div class="w-full flex flex-row justify-between items-center">
      <div
        class="flex flex-row cursor-pointer"
        @click="onClose()">
        <svg
          class="w-6 h-6"
          viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
        </svg>
        <span>Back</span>
      </div>
      <span>
        Member Order Histories
      </span>
    </div>
    <div class="w-full mt-5">
      <search-box
        v-model="searchInput"
        @on-search="fetchOrders(true)" />
    </div>
    <div
      v-if="!getting"
      class="mt-5 overflow-x-auto w-full">
      <table class="w-96 md:w-full text-sm mb-4">
        <thead>
          <tr>
            <th>วันที่</th>
            <th>ออเดอร์</th>
            <th class="text-right">
              ราคาสุทธิ
            </th>
            <th>โค้ด</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(payload, i) in orders"
            :key="i">
            <td class="text-center">
              {{ getDateTimeFormat(payload.createdAt) }}
            </td>
            <td>
              <div class="flex flex-row justify-center items-center">
                <div
                  class="rounded-full w-1 h-1 mr-0.5"
                  :class="isExpired(payload)" />
                <a
                  :href="getBillUrl(payload)"
                  class="no-underline text-black"
                  target="_blank">
                  {{ payload.order.id }}
                </a>
              </div>
            </td>
            <td class="text-right">
              {{ payload.order.net | showNumberFormat() }}
            </td>
            <td class="text-center">
              {{ checkPromotionCode(payload.promotionCode) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-else
      class="w-full py-10 flex justify-center items-center mt-5">
      <loading />
    </div>
    <div class="mt-5 w-full">
      <paginate
        v-model="page"
        :total="totalPage"
        :max-item="6"
        :disabled="getting" />
    </div>
  </div>
</template>

<script>
import Paginate from '../../../../components/Paginate.vue'
import SearchBox from '../../../../components/SearchBox.vue'
import MemberOrderHistoriesProvider from '../../../../resources/member-order-histories.provider'

const MemberOrderHistoriesService = new MemberOrderHistoriesProvider()

export default {
  components: { Paginate, SearchBox },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      orders: [],
      page: 1,
      perPage: 15,
      totalPage: 1,
      searchInput: '',
      getting: true
    }
  },
  watch: {
    page () {
      this.fetchOrders()
    }
  },
  mounted () {
    this.fetchOrders()
  },
  methods: {
    getBillUrl (payload) {
      return `${process.env.VUE_APP_LINK_BILL}/${payload.order.url}`
    },
    async fetchOrders (isClear = false) {
      try {
        this.getting = true

        if (isClear) {
          this.page = 1
        }

        const { data } = await MemberOrderHistoriesService.getMemberOrderHistories(this.id, {
          page: this.page,
          limit: this.perPage,
          search: this.searchInput
        })

        this.totalPage = data.pages
        this.orders = data.results
      } catch (error) {
        console.error('fetchOrders', error)
      } finally {
        this.getting = false
      }
    },
    getDateTimeFormat (dateTime) {
      return this.$dayjs(dateTime).format('DD/MM/YY HH.mm')
    },
    isExpired (payload) {
      const diff = this.$dayjs().isAfter(this.$dayjs(payload.order.expire))

      if (diff && payload.order.currentState === 'confirmed') {
        return 'expired'
      }

      return payload.order.currentState
    },
    checkPromotionCode (promotionCode) {
      if (!promotionCode || !promotionCode?.code) {
        return '-'
      }

      return promotionCode.code
    },
    onClose () {
      this.$emit('on-close')
    }
  }
}
</script>

<style scoped>
.paid {
  background-color: #9A648B;
}
.waiting {
  background-color: #60C3D7;
}
.expired {
  background-color: #CACACA;
}
.pending {
  background-color: #BFCB4D;
}
.reserved, .confirmed {
  background-color: #EDA441;
}
.completed {
  background-color: #2D5D72;
}
</style>
