<template>
  <div class="w-full flex flex-col justify-center items-center">
    <div class="self-start flex flex-row flex-wrap mb-2">
      <div
        v-for="(item, i) in selectedItems"
        :key="i"
        class="relative flex flex-row justify-center items-center rounded bg-yellow-200 text-xs mr-2 p-1">
        <span>{{ item }}</span>
        <div
          class="ml-1 cursor-pointer"
          @click="removeItem(i)">
          x
        </div>
      </div>
    </div>
    <div
      v-click-outside="reset"
      class="relative w-full h-10 rounded flex flex-row border px-1 py-1 bg-white duration-300 hover:border-gray-400"
      :class="isShowDropdown ? 'border-gray-400' : 'border-gray-200'">
      <div
        class="w-11/12 cursor-pointer flex items-center"
        @click="toggleDropdown()">
        <span v-if="selectedItems.length">
          {{ selectedItems[0] }}
        </span>
        <span
          v-else
          class="text-gray-400">
          {{ label }}
        </span>
      </div>
      <div
        class="w-1/12 flex justify-center items-center cursor-pointer text-xl"
        @click="toggleDropdown()">
        <icon
          icon="lucide:chevron-down"
          color="#000" />
      </div>
      <div
        v-show="isShowDropdown"
        class="absolute z-10 transform translate-y-8 mt-1 left-0 w-full bg-white rounded-b-lg shadow">
        <div
          v-for="(item, i) in items"
          :key="i"
          class="flex flex-row items-center hover:bg-gray-300 px-2 py-2 rounded cursor-pointer"
          @click="selectItem(item)">
          <input
            v-model="selectedItems"
            type="checkbox"
            :value="item"
            :disabled="disabled">
          <span class="ml-1">
            {{ item }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array || String,
      default: () => [] || ''
    },
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isShowDropdown: false
  }),
  computed: {
    selectedItems: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    toggleDropdown () {
      this.isShowDropdown = !this.isShowDropdown
    },
    selectItem (item) {
      if (!this.disabled) {
        if (!this.selectedItems.includes(item)) {
          this.selectedItems = [...this.selectedItems, item]
          this.$emit('on-select', item)
        } else {
          const index = this.selectedItems.findIndex((select) => select === item)

          if (index !== -1) {
            this.removeItem(index)
          }
        }
      }
    },
    removeItem (index) {
      if (!this.disabled) {
        this.selectedItems.splice(index, 1)
        this.$emit('on-remove', index)
      }
    },
    reset () {
      this.isShowDropdown = false
    }
  }
}
</script>
