import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class MemberOrderHistoriesProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_CHAT_API)
  }

  getMemberOrderHistories (id, query) {
    this.setHeader(getAuthToken())
    return this.get(`/member-order-histories/${id}`, query)
  }
}

export default MemberOrderHistoriesProvider
