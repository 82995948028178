import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class QuickMessageProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_CHAT_API)
  }

  getQuickMessages (payload) {
    this.setHeader(getAuthToken())
    return this.get('/quick-message', payload)
  }

  addQuickMessage (text) {
    this.setHeader(getAuthToken())
    return this.post('/quick-message', { text })
  }

  updateQuickMessage (id, text) {
    this.setHeader(getAuthToken())
    return this.put(`/quick-message/${id}`, { text })
  }

  deleteQuickMessage (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/quick-message/${id}`)
  }
}

export default QuickMessageProvider
