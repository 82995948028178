<template>
  <div class="fixed w-full h-full z-50 top-0 left-0 flex justify-center items-center bg-black bg-opacity-40">
    <div class="max-h-full relative elevation-3 rounded mx-3">
      <img
        class="rounded preview-image"
        :src="src"
        :alt="alt">

      <div
        class="absolute top-0 right-0 m-1 w-6 h-6 rounded-full cursor-pointer bg-black opacity-50 duration-500 hover:opacity-100"
        @click="closePreview()">
        <svg
          class="w-6 h-6"
          viewBox="0 0 24 24">
          <path
            fill="white"
            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  },
  methods: {
    closePreview () {
      this.$emit('on-close')
    }
  }
}
</script>

<style scoped>
.preview-image {
  max-height: 98vh !important;
}
</style>
