<template>
  <div class="w-full h-screen relative">
    <div class="w-full grid grid-cols-12 pl-10 pr-4 border-b border-gray-200 fixed md:relative z-10 top-0 left-0 bg-white">
      <div class="col-span-8 flex flex-row items-center my-2">
        <img
          v-if="isNotNull(guestData, 'photoUrl')"
          class="rounded-full w-10 h-10"
          :src="guestData.photoUrl"
          alt="profile">
        <div
          v-else
          class="rounded-full w-10 h-10 bg-gray-300 flex justify-center items-center">
          <span v-if="isNotNull(guestData, 'displayName')">
            {{ getFirstLetter(guestData.displayName) }}
          </span>
          <span v-else>
            {{ getFirstLetter('Gentlewoman') }}
          </span>
        </div>

        <span
          v-if="isNotNull(guestData, 'displayName')"
          class="font-bold text-sm ml-2">
          {{ guestData.displayName }}
        </span>
      </div>
      <div
        v-if="guestData"
        class="col-span-4 flex justify-end items-center my-2">
        <toggle
          v-model="chatMode"
          true-text="Admin"
          true-value="chat"
          false-value="bot"
          class="mr-1" />
        <div
          class="text-2xl cursor-pointer"
          @click="toggleRightPanel()">
          <icon
            icon="iwwa:option"
            color="#9ca3af" />
        </div>
      </div>
    </div>
    <template v-if="guestData">
      <LineChatArea
        v-if="guestData.channel === 'LINE'"
        :guest-data="guestData" />
      <FacebookChatArea
        v-if="guestData.channel === 'facebook'"
        :guest-data="guestData" />
    </template>
    <reply-box
      v-if="guestData"
      :guest-data="guestData"
      :uid="uid"
      :channel="guestData.channel"
      class="border-t border-gray-200" />
  </div>
</template>

<script>
import SocialGuestProvider from '../../../resources/social-guest.provider'
import LineChatArea from './LineChatArea.vue'
import FacebookChatArea from './FacebookChatArea.vue'
import ReplyBox from './ReplyBox.vue'
import Toggle from '../../../components/Toggle.vue'

const SocialGuestService = new SocialGuestProvider()

export default {
  components: { ReplyBox, LineChatArea, FacebookChatArea, Toggle },
  props: {
    uid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      switchLoading: false,
      page: 0,
      perPage: 15,
      totalPage: 1,
      guestData: null,
      messages: [],
      isShowPreview: false,
      showPreviewSource: {
        src: '',
        alt: ''
      },
      chatMode: 'bot'
    }
  },
  watch: {
    async uid () {
      await this.initGuest()
    },
    chatMode () {
      this.switchMode(this.chatMode)
    }
  },
  mounted () {
    if (this.uid !== null || this.uid !== '') {
      this.initGuest()
    }
  },
  methods: {
    async initGuest () {
      try {
        this.loading = true

        const { data } = await SocialGuestService.getGuestByUid(this.uid)
        this.guestData = data
        this.chatMode = data.chatMode

        await SocialGuestService.readMessage(this.guestData.id)
      } catch (error) {
        console.error('initGuest', error)
      } finally {
        this.loading = false
      }
    },
    getFirstLetter (text) {
      const regex = /([A-z])/
      const result = regex.exec(text)

      if (result) {
        return result[0].toUpperCase()
      }

      return 'GW'
    },
    checkModeColor (mode) {
      if (mode === 'bot') {
        return 'bg-green-500 hover:bg-green-400'
      }

      return 'bg-blue-400 hover:bg-blue-500'
    },
    async switchMode (mode) {
      try {
        this.switchLoading = true

        await SocialGuestService.switchMode(this.guestData.id, mode)
        await this.initGuest()
      } catch (error) {
        console.error('switchMode', error)
      } finally {
        this.switchLoading = false
      }
    },
    isNotNull (obj, target) {
      if (obj && obj[target]) {
        return true
      }

      return false
    },
    toggleRightPanel () {
      this.$emit('toggle-panel')
    }
  }
}
</script>

<style scoped>

</style>
