<!-- eslint-disable max-len -->
<template>
  <div
    class="
      absolute
      z-10
      w-full
      h-28
      content-position
      px-6
    ">
    <div class="w-full h-full p-3 bg-white flex flex-row items-center overflow-x-auto gap-1 rounded-t-lg border-r border-l border-t border-gray-200">
      <img
        v-for="(sticker, index) in computedStickers"
        :key="`package-${index}`"
        class="h-full w-auto cursor-pointer hover:bg-gray-200 rounded-lg duration-300"
        :src="`https://stickershop.line-scdn.net/stickershop/v1/sticker/${getStickerId(sticker)}/android/sticker.png`"
        :alt="`Sticker-${getStickerId(sticker)}`"
        @click="selectSticker(sticker)">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      defaultPackage: null,
      isMounted: false
    }
  },
  computed: {
    ...mapGetters({
      stickers: 'Stickers/stickers'
    }),
    selectedSticker: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    computedStickers () {
      if (this.defaultPackage && this.defaultPackage.packageId) {
        return this.defaultPackage.stickerIds
      }

      return this.stickers
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    selectSticker (stickerId) {
      if (this.defaultPackage) {
        this.selectedSticker = {
          packageId: this.defaultPackage.packageId,
          stickerId
        }

        this.$emit('on-select')
        return
      }

      this.defaultPackage = stickerId
    },
    getStickerId (sticker) {
      if ((typeof sticker === 'object') && 'stickerIds' in sticker) {
        return sticker.stickerIds[0]
      }

      return sticker
    }
  }
}
</script>

<style scoped>
.content-position {
  left: 0;
  bottom: 80px;
}
</style>
