<template>
  <div
    class="flex flex-row max-w-max gap-x-2"
    :class="(messageType === 'send') ? 'self-end' : ''">
    <div
      class="rounded-full w-10 h-10 bg-gray-300 flex justify-center items-center"
      :class="(messageType === 'send') ? 'order-last' : ''">
      <span>
        {{ getFirstLetter(message.from.name) }}
      </span>
    </div>
    <div class="flex flex-col">
      <div
        class="w-full mb-2 text-xs"
        :class="(messageType === 'send') ? 'text-right' : 'text-left'">
        <span class="font-semibold">
          {{ message.from.name }}
        </span>
      </div>
      <div
        v-if="message.message !== ''"
        style="white-space: pre-line"
        class="text-sm p-2 rounded-lg bubble-max-width text-black"
        :class="(messageType === 'send') ? 'sender-bg self-end rounded-tr-none' : 'target-bg rounded-tl-none'">
        {{ message.message }}
      </div>
      <div
        v-else-if="isImage"
        class="w-full flex cursor-pointer rounded-lg p-2"
        :class="(messageType === 'send') ? 'justify-end sender-bg' : 'justify-start target-bg'"
        @click="showPreview(message.attachments.data[0].imageData.url, 'Image')">
        <img
          class="w-32 h-32 object-contain"
          :src="message.attachments.data[0].imageData.previewUrl"
          alt="Image">
      </div>
      <div
        v-else-if="message.sticker"
        class="w-full flex"
        :class="(messageType === 'send') ? 'justify-end' : 'justify-start'">
        <img
          class="rounded w-32 h-32 object-contain"
          :src="message.sticker"
          alt="Sticker">
      </div>
      <div
        v-else
        class="flex flex-row flex-wrap text-sm p-2 rounded-lg text-black"
        :class="(messageType === 'send') ? 'sender-bg rounded-tr-none' : 'target-bg rounded-tl-none'">
        ไม่สามารถแสดงข้อความนี้บน Browser ได้
      </div>
      <div
        class="w-full mt-2 text-xs"
        :class="(messageType === 'send') ? 'text-right' : 'text-left'">
        <span style="color: #cecece;">
          {{ getTimeFormat(message.createdTime) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    messageType () {
      return (this.message.from.id === '408103239656656' || this.message.from.id === '100162172694362') ? 'send' : 'receive'
    },
    isImage () {
      return this.message?.attachments?.data[0]?.imageData || false
    }
  },
  methods: {
    getFirstLetter (text) {
      const regex = /([A-z])/
      const result = regex.exec(text)

      if (result) {
        return result[0].toUpperCase()
      }

      return 'G'
    },
    getTimeFormat (time) {
      const timeFormat = this.$dayjs(time).format('HH:mm')
      const dateFormat = this.$dayjs(time).format('D MMM YY')
      return `${timeFormat} | ${dateFormat}`
    },
    showPreview (src, alt) {
      this.$emit('on-show-preview', { src, alt })
    }
  }
}
</script>

<style scoped>
.target-bg {
  background-color: #efefef;
}
.sender-bg {
  background-color: #dedede;
}
.bubble-max-width {
  width: fit-content;
  word-wrap: break-word;
  max-inline-size: 50ch;
}

@media screen and (max-width: 768px) {
  .bubble-max-width {
    max-inline-size: 35ch;
  }
}
</style>
