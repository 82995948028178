<!-- eslint-disable max-len -->
<template>
  <div
    class="
      absolute
      z-10
      w-full
      h-56
      content-position
      px-6
    ">
    <div class="w-full h-full flex flex-col gap-y-3 p-3 bg-white rounded-t-lg border-r border-l border-t border-gray-200">
      <div class="w-full h-28 p-2 flex flex-row items-center overflow-x-auto gap-2 shadow rounded-lg">
        <div
          v-for="(image, i) in images"
          :key="i"
          class="relative w-fit h-full rounded-lg shadow"
          :class="checkImageUrl(image)">
          <img
            class="w-auto h-full object-contain rounded-lg"
            :src="image.preview"
            :alt="`Image-${i}`">
          <div
            class="
              absolute
              z-10
              left-1/2
              top-1/2
              transform
              -translate-x-1/2
              -translate-y-1/2
              w-full
              h-full
              text-xl
              rounded-lg
              bg-black
              opacity-0
              duration-300
              cursor-pointer
              hover:bg-opacity-70
              hover:opacity-100
              flex
              justify-center
              items-center
            "
            style="color: #FF6347;"
            @click="removeItem(i)">
            <icon icon="mdi:delete-empty" />
          </div>
        </div>
        <span
          v-if="!images.length"
          class="mx-auto text-gray-300 select-none">
          Add some image...
        </span>
      </div>
      <div class="w-full h-28">
        <file-uploader
          ref="imageUploader"
          v-model="images" />
      </div>
    </div>
  </div>
</template>

<script>
import FileUploader from '../../../../components/FileUploader.vue'

export default {
  components: { FileUploader },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    images: {
      get () {
        return this.value
      },
      set (val) {
        this.value = val
      }
    }
  },
  methods: {
    removeItem (index) {
      this.$refs.imageUploader.removeItem(index)
    },
    checkImageUrl (image) {
      return (image?.mediaUrl) ? 'opacity-100' : 'opacity-60'
    }
  }
}
</script>

<style scoped>
.content-position {
  left: 0;
  bottom: 80px;
}
</style>
