<template>
  <div class="w-full overflow-y-auto overflow-x-hidden chat-area">
    <div
      v-if="getting"
      class="w-full flex justify-center items-center"
      style="background-color: #F9F9F9;">
      <loading />
    </div>
    <div
      id="chat-area-line"
      class="w-full h-full overflow-y-auto overflow-x-hidden"
      style="background-color: #F9F9F9;">
      <div
        v-for="(msg, i) in messages"
        :key="i"
        class="w-full flex flex-col p-2">
        <line-chat-bubble
          :message="msg.message"
          :message-type="msg.type"
          :send-by="msg.sender.displayName"
          :send-profile="msg.sender.photoUrl || require('@/assets/no-img.png')"
          :send-time="msg.createdAt"
          @on-show-preview="showPreview($event)" />
      </div>
    </div>
    <image-preview
      v-if="isShowPreview"
      :src="showPreviewSource.src"
      :alt="showPreviewSource.alt"
      @on-close="closePreview()" />
  </div>
</template>

<script>
import SocialChatProvider from '../../../resources/social-chat.provider'
import LineChatBubble from './LineChatBubble.vue'
import ImagePreview from './ImagePreview.vue'

const SocialChatService = new SocialChatProvider()
export default {
  components: { LineChatBubble, ImagePreview },
  props: {
    guestData: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      getting: false,
      page: 0,
      perPage: 15,
      totalPage: 1,
      messages: [],
      showPreviewSource: {
        src: '',
        alt: ''
      },
      isShowPreview: false
    }
  },
  computed: {
    uid () {
      return this.$route.params.uid ? this.$route.params.uid : ''
    }
  },
  watch: {
    guestData: {
      deep: true,
      handler () {
        this.initMessage(true)
      }
    }
  },
  async mounted () {
    this.sockets.subscribe('NEW_MESSAGE', (data) => {
      if (data.guest.uid === this.uid) {
        this.messages.push(data)
        setTimeout(() => {
          this.initScrollY()
        }, 50)
      }
    })

    document.getElementById('chat-area-line').addEventListener('scroll', this.handleScroll)
    await this.initMessage(true)
  },
  beforeDestroy () {
    document.getElementById('chat-area-line').removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    closePreview () {
      this.isShowPreview = false
      this.showPreviewSource = {
        src: '',
        alt: ''
      }
    },
    initScrollY () {
      // const scrollY = document.getElementById('chat-area-line').scrollHeight - document.getElementById('chat-area-line').clientHeight
      document.getElementById('chat-area-line').scrollTo(0, document.getElementById('chat-area-line').scrollHeight)
    },
    handleScroll (event) {
      const e = event.target

      if (e.scrollTop <= 0 && !this.getting) {
        this.initMessage(false, false)
      }
    },
    async initMessage (isClear = false, isScroll = true) {
      try {
        this.getting = true

        if (isClear) {
          this.messages = []
          this.page = 0
          this.totalPage = 1
        }

        if (this.page < this.totalPage) {
          this.page++

          const { data } = await SocialChatService.getMessages({
            page: this.page,
            limit: this.perPage,
            uid: this.uid,
            channel: this.guestData.channel
          })

          this.totalPage = data.pages
          const tmpResults = data.results

          // Handle Message from Dialogflow
          for (const result of tmpResults) {
            if (!result.message?.type) {
              result.message = {
                type: 'text',
                text: result.message
              }
            }
          }

          this.messages.unshift(...tmpResults.reverse())

          if (isScroll) {
            setTimeout(() => {
              this.initScrollY()
            }, 50)
          }
        }
      } catch (error) {
        console.error('initMessage', error)
      } finally {
        this.getting = false
      }
    },
    showPreview ({ src, alt }) {
      this.showPreviewSource = {
        src,
        alt
      }
      this.isShowPreview = true
    }
  }
}
</script>

<style scoped>
.chat-area {
  height: calc(100vh - 136px);
}

@media (max-width: 767px) {
  .chat-area {
    height: calc(100vh - 79px);
    padding-top: 57px;
  }
}
</style>
