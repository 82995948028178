import S3 from 'aws-sdk/clients/s3'

const uploadFile = (file, path) => {
  const fileType = file.name.split('.')
  const fileName = `${new Date().getTime()}.${fileType[fileType.length - 1]}`
  const params = {
    Key: fileName,
    ContentType: file.type,
    Body: file,
    ACL: 'public-read'
  }

  const bucket = new S3({
    region: process.env.VUE_APP_S3_REGION,
    accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
    params: {
      Bucket: `${process.env.VUE_APP_S3_BUCKET}${path}`
    }
  })

  return new Promise((resolve, reject) => {
    bucket.putObject(params, function a (err) {
      if (err) {
        reject(err)
      } else {
        resolve(this.request.params)
      }
    })
  })
}

const uploadToBucket = async (file, path) => {
  let image = null
  try {
    const res = await uploadFile(file, path)
    image = {
      mediaName: res.Body.name,
      mediaUrl: `${process.env.VUE_APP_S3_URL}/${res.Key}`,
      size: res.Body.size,
      mediaType: res.Body.type,
      tempId: res.Body.uid
    }
  } catch (e) {
    console.error('uploadToBucket catch', e)
  }
  return image
}

export default uploadToBucket

export { uploadToBucket }
