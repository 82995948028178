<template>
  <div class="h-10 flex flex-row items-center justify-center">
    <div
      class="mx-1 w-6 h-6 rounded-full cursor-pointer duration-500"
      :class="disablePrev ? 'bg-gray-400' : 'bg-black hover:bg-gray-600'"
      @click="previousPage()">
      <svg
        class="w-full h-full"
        viewBox="0 0 24 24">
        <path
          fill="white"
          d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
      </svg>
    </div>
    <div
      v-for="(item, i) in getTotal()"
      :key="i"
      class="mx-1 w-6 h-6 flex justify-center items-center rounded-full cursor-pointer duration-500"
      :class="isActive(item.index)"
      @click="changePage(item.index)">
      <span class="text-xs">
        {{ item.label }}
      </span>
    </div>
    <div
      class="mx-1 w-6 h-6 rounded-full cursor-pointer duration-500"
      :class="disableNext ? 'bg-gray-400' : 'bg-black hover:bg-gray-600'"
      @click="nextPage()">
      <svg
        class="w-full h-full"
        viewBox="0 0 24 24">
        <path
          fill="white"
          d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 1
    },
    maxItem: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    page: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    disableNext () {
      return this.page === this.total || this.disabled
    },
    disablePrev () {
      return this.page === 1 || this.disabled
    }
  },
  methods: {
    getTotal () {
      const items = []

      if (this.maxItem && (this.total > this.maxItem)) {
        for (let i = 0; i < this.maxItem; i++) {
          const half = Math.ceil(this.maxItem / 2)

          if (this.page < (this.total - this.maxItem + 1)) {
            if (i > (half - 1)) {
              items.push({
                index: i + (this.total - this.maxItem + 1),
                label: i + (this.total - this.maxItem + 1)
              })
            } else {
              items.push({
                index: i + this.page,
                label: i + this.page
              })
            }

            if (i === (half - 1)) {
              items.push({
                index: -1,
                label: '...'
              })
            }
          } else {
            items.push({
              index: i + (this.total - this.maxItem + 1),
              label: i + (this.total - this.maxItem + 1)
            })
          }
        }
      } else {
        for (let i = 1; i <= this.total; i++) {
          items.push({
            index: i,
            label: i
          })
        }
      }

      return items
    },
    changePage (index) {
      if (index === -1 || this.disabled) {
        return
      }

      this.page = index
    },
    nextPage () {
      if (!this.disableNext) {
        this.page++
      }
    },
    previousPage () {
      if (!this.disablePrev) {
        this.page--
      }
    },
    isActive (index) {
      return (index === this.page) ? 'bg-black text-white' : 'bg-white text-black border border-black hover:bg-black hover:text-white'
    }
  }
}
</script>
