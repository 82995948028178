import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class MemberProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_CHAT_API)
  }

  getMemberDetail (id) {
    this.setHeader(getAuthToken())
    return this.get(`/member/${id}`)
  }
}

export default MemberProvider
