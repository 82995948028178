<!-- eslint-disable max-len -->
<template>
  <div
    class="
      absolute
      z-10
      w-full
      h-56
      content-position
      px-6
    ">
    <div class="w-full h-full p-3 bg-white flex flex-col gap-1 rounded-t-lg border-r border-l border-t border-gray-200">
      <!-- Header -->
      <div class="w-full h-8 text-sm grid grid-cols-6 gap-2">
        <input
          v-model="searchInput"
          type="text"
          placeholder="ค้นหา"
          class="border border-white hover:border-gray-200 outline-none col-span-4 md:col-span-5 px-2 duration-300 rounded"
          :disabled="loading"
          @keydown.enter="onSearch()" />
        <button
          class="bg-gray-600 text-white px-3 py-1 duration-300 hover:bg-gray-700 rounded col-span-2 md:col-span-1"
          :disabled="loading"
          @click="toggleManagement()">
          {{ (!isManagementActive) ? 'จัดการ' : 'ปิด' }}
        </button>
      </div>

      <div
        ref="quickMessage"
        class="w-full h-5/6 overflow-y-auto text-sm px-1 pt-1">
        <div
          v-if="isManagementActive"
          class="w-full h-8 grid grid-cols-8 px-1">
          <input
            v-model="quickMessageInput"
            type="text"
            placeholder="Add Quick Message"
            class="border border-white hover:border-gray-200 outline-none px-2 pb-0.5 col-span-7 duration-300 rounded"
            :disabled="loading"
            @keydown.enter="addQuickMessage()">
          <div class="w-full flex items-center justify-end">
            <div
              class="w-fit text-xl cursor-pointer text-gray-400 hover:text-black"
              @click="addQuickMessage()">
              <icon icon="mdi:message-add" />
            </div>
          </div>
        </div>
        <div
          v-for="quickMessage in quickMessages"
          :key="quickMessage.id"
          class="w-full grid grid-cols-8 px-1"
          style="min-height: 32px;">
          <input
            v-if="isManagementActive"
            v-model="quickMessage.text"
            type="text"
            placeholder="Quick Message"
            class="border border-white hover:border-gray-200 outline-none px-2 pb-0.5 col-span-7 duration-300 rounded"
            :disabled="loading"
            @keydown.enter="updateQuickMessage(quickMessage)">
          <span
            v-else
            class="col-span-7 duration-300 hover:bg-gray-300 px-1 py-3 rounded cursor-pointer"
            @click="setMessage(quickMessage.text)">
            {{ quickMessage.text }}
          </span>

          <div
            v-if="isManagementActive"
            class="w-full flex items-center justify-end">
            <div
              class="w-fit text-xl cursor-pointer text-gray-400 hover:text-black duration-300"
              @click="updateQuickMessage(quickMessage)">
              <icon icon="ph:check-bold" />
            </div>
            <div
              class="w-fit text-xl cursor-pointer text-gray-400 hover:text-black duration-300"
              @click="deleteQuickMessage(quickMessage)">
              <icon icon="mdi:delete-empty" />
            </div>
          </div>
          <div
            v-else
            class="w-full flex items-center justify-end">
            <div
              class="w-fit text-xl cursor-pointer text-gray-400 hover:text-black duration-300"
              @click="pushMessage(quickMessage.text)">
              <icon icon="uil:message" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuickMessageProvider from '../../../../resources/quick-message.provider'

const QuickMessageService = new QuickMessageProvider()

export default {
  data () {
    return {
      loading: false,
      searchInput: '',
      quickMessageInput: '',
      page: 0,
      perPage: 10,
      totalPage: 1,
      quickMessages: [],
      isManagementActive: false
    }
  },
  mounted () {
    this.$refs.quickMessage.addEventListener('scroll', this.handleScroll)
    this.fetchQuickMessages()
  },
  beforeDestroy () {
    this.$refs.quickMessage.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (event) {
      const e = event.target
      const scrollY = e.scrollHeight - e.clientHeight
      if (e.scrollTop >= scrollY && !this.loading) {
        this.fetchQuickMessages()
      }
    },
    async fetchQuickMessages (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.quickMessages = []
          this.page = 0
          this.totalPage = 1
        }

        if (this.page < this.totalPage) {
          this.page++

          const { data } = await QuickMessageService.getQuickMessages({
            page: this.page,
            limit: this.perPage,
            search: this.searchInput
          })

          this.totalPage = data.pages
          this.quickMessages.push(...data.results)
        }
      } catch (error) {
        console.error('fetchQuickMessages', error)
      } finally {
        this.loading = false
      }
    },
    onSearch () {
      this.fetchQuickMessages(true)
    },
    toggleManagement () {
      this.isManagementActive = !this.isManagementActive
    },
    async addQuickMessage () {
      try {
        this.loading = true

        await QuickMessageService.addQuickMessage(this.quickMessageInput)

        this.quickMessageInput = ''

        await this.fetchQuickMessages(true)
      } catch (error) {
        console.error('addQuickMessage', error)
      } finally {
        this.loading = false
      }
    },
    async updateQuickMessage (quickMessage) {
      if (!quickMessage.text) {
        this.deleteQuickMessage(quickMessage)
        return
      }

      try {
        this.loading = true

        await QuickMessageService.updateQuickMessage(quickMessage.id, quickMessage.text)

        await this.fetchQuickMessages(true)
      } catch (error) {
        console.error('updateQuickMessage', error)
      } finally {
        this.loading = false
      }
    },
    async deleteQuickMessage (quickMessage) {
      try {
        this.loading = true

        await QuickMessageService.deleteQuickMessage(quickMessage.id)

        await this.fetchQuickMessages(true)
      } catch (error) {
        console.error('deleteQuickMessage', error)
      } finally {
        this.loading = false
      }
    },
    pushMessage (message) {
      this.$emit('push-message', message)
    },
    setMessage (message) {
      this.$emit('set-message', message)
    }
  }
}
</script>

<style scoped>
.content-position {
  left: 0;
  bottom: 80px;
}
</style>
