<template>
  <div class="mt-5 w-full h-full flex flex-col items-center">
    <div class="w-full flex flex-row justify-between items-center">
      <div
        class="flex flex-row cursor-pointer"
        @click="onClose()">
        <svg
          class="w-6 h-6"
          viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
        </svg>
        <span>Back</span>
      </div>
      <span>
        Member Promotion Codes
      </span>
    </div>
    <div class="w-full mt-5">
      <search-box
        v-model="searchInput"
        @on-search="fetchPromotionCodes(true)" />
    </div>
    <div
      v-if="!getting"
      class="mt-5 overflow-x-auto w-full">
      <table class="w-full">
        <thead>
          <tr>
            <th class="font-semibold">
              Code
            </th>
            <th class="font-semibold">
              มูลค่า
            </th>
            <th class="font-semibold">
              วันหมดอายุ
            </th>
            <th class="font-semibold">
              การใช้งาน
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(promo, i) in promotionCodes"
            :key="i">
            <td class="text-center text-sm">
              {{ promo.code }}
            </td>
            <td class="text-center text-sm">
              {{ checkDiscount(promo.discountNumber, promo.discountType) }}
            </td>
            <td class="text-center text-sm">
              {{ getExpireDate(promo.dateRange) }}
            </td>
            <td class="text-center text-sm">
              {{ checkPromoStatus(promo.status) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-else
      class="w-full py-10 flex justify-center items-center mt-5">
      <loading />
    </div>
    <div class="mt-5 w-full">
      <paginate
        v-model="page"
        :total="totalPage"
        :max-item="6"
        :disabled="getting" />
    </div>
  </div>
</template>

<script>
import PromotionCodeProvider from '../../../../resources/promotion-code.provider'
import Paginate from '../../../../components/Paginate.vue'
import SearchBox from '../../../../components/SearchBox.vue'

const PromotionCodeService = new PromotionCodeProvider()

export default {
  components: { Paginate, SearchBox },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      promotionCodes: [],
      page: 1,
      perPage: 15,
      totalPage: 1,
      searchInput: '',
      getting: false
    }
  },
  watch: {
    page () {
      this.fetchPromotionCodes()
    }
  },
  mounted () {
    this.fetchPromotionCodes()
  },
  methods: {
    async fetchPromotionCodes (isClear = false) {
      try {
        this.getting = true

        if (isClear) {
          this.page = 1
        }

        const { data } = await PromotionCodeService.getMemberPromotionCode(this.id, {
          page: this.page,
          limit: this.perPage,
          search: this.searchInput
        })

        this.totalPage = data.pages
        this.promotionCodes = data.results
      } catch (error) {
        console.error('fetchPromotionCodes', error)
      } finally {
        this.getting = false
      }
    },
    getDateTimeFormat (dateTime) {
      return this.$dayjs(dateTime).format('DD/MM/YY HH.mm')
    },
    checkDiscount (number, type) {
      if (type === 'percentage') {
        return `${number}%`
      }

      return number
    },
    getExpireDate (dateRange) {
      if (dateRange.endDate) {
        return this.$dayjs(dateRange.endDate).format('DD MMM YYYY')
      }

      return '-'
    },
    checkPromoStatus (status) {
      return (status === 'used') ? 'Yes' : 'No'
    },
    onClose () {
      this.$emit('on-close')
    }
  }
}
</script>

<style scoped>
.paid {
  background-color: #9A648B;
}
.waiting {
  background-color: #60C3D7;
}
.expired {
  background-color: #CACACA;
}
.pending {
  background-color: #BFCB4D;
}
.reserved, .confirmed {
  background-color: #EDA441;
}
.completed {
  background-color: #2D5D72;
}
</style>
