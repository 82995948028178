import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class SocialGuestProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_CHAT_API)
  }

  getGuest (payload) {
    this.setHeader(getAuthToken())
    return this.get('/social-guest', payload)
  }

  getGuestByUid (uid) {
    this.setHeader(getAuthToken())
    return this.get(`/social-guest/${uid}/uid`)
  }

  getUnReadCount (query) {
    this.setHeader(getAuthToken())
    return this.get('/social-guest/unread-count', query)
  }

  readMessage (guestId) {
    this.setHeader(getAuthToken())
    return this.patch(`/social-guest/${guestId}/read`)
  }

  addTag (id, tag) {
    this.setHeader(getAuthToken())
    return this.post('/social-guest/tag', { id, tag })
  }

  deleteTag (id, tagIndex) {
    this.setHeader(getAuthToken())
    return this.remove('/social-guest/tag', { id, tagIndex })
  }

  addTodo (id, text) {
    this.setHeader(getAuthToken())
    return this.post('/social-guest/todo', { id, text })
  }

  updateTodo (id, todoIndex) {
    this.setHeader(getAuthToken())
    return this.put('/social-guest/todo', { id, todoIndex })
  }

  deleteTodo (id, todoIndex) {
    this.setHeader(getAuthToken())
    return this.remove('/social-guest/todo', { id, todoIndex })
  }

  switchMode (id, chatMode) {
    this.setHeader(getAuthToken())
    return this.put('/social-guest/switch-mode', { id, chatMode })
  }
}

export default SocialGuestProvider
