import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class SocialChatProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_CHAT_API)
  }

  getMessages (payload) {
    this.setHeader(getAuthToken())
    return this.get('/social-chat', payload)
  }

  getMessageFacebook (payload) {
    this.setHeader(getAuthToken())
    return this.get('/social-chat/facebook', payload)
  }

  pushLINEMessages (payload) {
    this.setHeader(getAuthToken())
    return this.post('/social-chat/line/texts', payload)
  }

  pushLINEStickers (payload) {
    this.setHeader(getAuthToken())
    return this.post('/social-chat/line/stickers', payload)
  }

  pushLINEImages (payload) {
    this.setHeader(getAuthToken())
    return this.post('/social-chat/line/images', payload)
  }

  pushFacebookMessage (payload) {
    this.setHeader(getAuthToken())
    return this.post('/social-chat/facebook/text', payload)
  }

  pushFacebookImage (payload) {
    this.setHeader(getAuthToken())
    return this.post('/social-chat/facebook/image', payload)
  }
}

export default SocialChatProvider
