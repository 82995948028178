<!-- eslint-disable max-len -->
<template>
  <div
    class="
      absolute
      z-10
      w-full
      h-56
      content-position
      px-6
    ">
    <div class="w-full h-full flex flex-col gap-y-3 p-3 bg-white rounded-t-lg border-r border-l border-t border-gray-200">
      <div class="w-full h-28 p-2 flex flex-row items-center overflow-x-auto gap-2 shadow rounded-lg">
        <div
          v-for="(file, i) in files"
          :key="i"
          class="relative w-fit p-1 h-full flex items-center justify-between rounded-md shadow border border-gray-200">
          <icon icon="material-symbols:attach-file" />
          <span>
            {{ cutWord(file.mediaName, 20) }}
          </span>
          <div
            class="
              absolute
              z-10
              left-1/2
              top-1/2
              transform
              -translate-x-1/2
              -translate-y-1/2
              w-full
              h-full
              text-xl
              rounded-lg
              bg-black
              opacity-0
              duration-300
              cursor-pointer
              hover:bg-opacity-70
              hover:opacity-100
              flex
              justify-center
              items-center
            "
            style="color: #FF6347;"
            @click="removeItem(i)">
            <icon icon="mdi:delete-empty" />
          </div>
        </div>
        <span
          v-if="!files.length"
          class="mx-auto text-gray-300 select-none">
          Add some file...
        </span>
      </div>
      <div class="w-full h-28">
        <file-uploader
          ref="fileUploader"
          v-model="files" />
      </div>
    </div>
  </div>
</template>

<script>
import FileUploader from '../../../../components/FileUploader.vue'

export default {
  components: { FileUploader },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    files: {
      get () {
        return this.value
      },
      set (val) {
        this.value = val
      }
    }
  },
  methods: {
    cutWord (word, limit) {
      if (word?.length > limit) {
        return `${word.substr(0, limit / 2)} ... ${word.substr(limit / 2)}`
      }

      return word
    },
    removeItem (index) {
      this.$refs.fileUploader.removeItem(index)
    }
  }
}
</script>

<style scoped>
.content-position {
  left: 0;
  bottom: 80px;
}
</style>
